export const Content = {
  FR: {
    home: {
      title: 'Réussir sa présence enligne avec **PME Sur le Web**',
    },
    about: {
      title: 'À propos',
    },
    blog: {
      url: 'articles',
      all_posts: 'Toutes les publications',
      return_blog: 'Retour aux publications',
      search: 'Rechercher',
      published: 'Publié le',
      read_more: 'Lire la suite',
      previous: 'Article précédent',
      next: 'Article suivant',
      no_posts: 'Aucune publication trouvée',
    },
    error_404: {
      title: 'Page non trouvée',
      description:
        "Mais ne vous inquiétez pas, vous pouvez trouver beaucoup d'autres choses sur notre page d'accueil.",
      return_home: 'Retour à la page d’accueil',
    },
    newsletters: {
      title: 'Inscrivez-vous à notre newsletter',
      already_subscribed: 'Vous êtes déjà inscrit à notre newsletter',
      success: 'Super! 🎉 Vous êtes inscrit à notre newsletter',
      address: 'Adresse courriel',
      placeholder: 'Votre adresse courriel',
      placeholder_success: 'Vous êtes inscrit 🎉!',
      button: {
        subscribe: 'S’inscrire',
        subscribed: 'Merci!',
      },
    },
  },
}
